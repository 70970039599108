<template>
    <div ref="customAModal" class="custom-a-modal">
        <!-- PC 端 -->
        <AModal
            v-if="!ifMatched"
            v-model:visible="visible"
            :centered="true"
            :get-container="() => $refs.customAModal as HTMLElement"
            :footer="null"
            width="684px"
            @cancel="handleButtonCancel"
        >
            <div class="item" @click="toStudio">
                <img src="../../assets/imgs/Home/individual_user_role.png" alt="" />
                <span class="text">{{ $t('global_link_Individual') }}</span>
            </div>
            <div class="item border" @click="toApplyTrial">
                <img src="../../assets/imgs/Home/corporate_user_role.png" alt="" />
                <span class="text">{{ $t('global_link_Corporate') }}</span>
            </div>
        </AModal>
        <!-- 移动 端 -->
        <AModal
            v-if="ifMatched"
            v-model:visible="visible"
            :centered="true"
            :get-container="() => $refs.customAModal as HTMLElement"
            :footer="null"
            width="342px"
            @cancel="handleButtonCancel"
        >
            <div class="item" @click="toStudio">
                <img src="../../assets/imgs/Home/individual_user_role.png" alt="" />
                <span class="text">{{ $t('global_link_Individual') }}</span>
            </div>
            <div class="item border" @click="toApplyTrial">
                <img src="../../assets/imgs/Home/corporate_user_role.png" alt="" />
                <span class="text">{{ $t('global_link_Corporate') }}</span>
            </div>
        </AModal>
    </div>
</template>

<script lang="ts" setup>
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import { computed, reactive, onMounted, onUnmounted, ref } from 'vue';

    const visible = ref<boolean>(false);
    const { $bus, $lenis } = useNuxtApp();
    const isSubmitting = ref<boolean>(false);
    const isCN = ifCNLocale();
    const ifMatched = ref(false);

    // 打开与关闭弹窗
    const showButton = () => {
        // 滚动阻塞
        $lenis.stop();
        visible.value = true;
    };
    const handleButtonCancel = () => {
        // 滚动启用
        $lenis.start();
        visible.value = false;
    };

    const toStudio = () => {
        window.open('http://studio.style3d.com/');
    };
    const toApplyTrial = () => {
        if (ifMatched.value) {
            // 移动 端，展开Header
            $bus.$emit('show:mobileNav');
            handleButtonCancel();
        } else {
            // PC 端，打开表单
            $bus.$emit('show:applyModal');
            handleButtonCancel();
        }
    };

    onMounted(() => {
        ifMatched.value = matchMobile();
        // 监听事件
        $bus.$on('show:applyButton', () => {
            showButton();
        });
        $bus.$on('close:applyButton', () => {
            handleButtonCancel();
        });
    });

    defineExpose({ showButton, handleButtonCancel });
</script>
<style lang="less">
    .custom-a-modal {
        .apply-for-trial-form.cn.dialog {
            .ant-input {
                background-color: transparent;
            }
        }
        .apply-for-trial-form.en.dialog {
            .ant-input {
                background-color: transparent;
            }
        }
    }
</style>
<style lang="less" scoped>
    @modal-border-radius: 20px;

    .custom-a-modal {
        font-family: Montserrat, 'HarmonyOS Sans SC';
        user-select: none;

        :deep(.ant-modal-content) {
            color: #ffffff;
            margin: 0 auto;
            border-radius: @modal-border-radius;
            background: rgba(0, 0, 0, 1) !important;
            // backdrop-filter: blur(20px);
            border: 0;
            position: relative;

            .ant-modal-close {
                // svg {
                //     color: #fff;
                // }
                visibility: hidden;
                .custom-a-modal-close {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                }
                & {
                    opacity: 0.5;
                    transition: all 0.5s ease-in-out;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
            .ant-modal-body {
                width: 684px;
                height: 170px;

                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                @media (max-width: @screen-mobile-width) {
                    width: 342px;
                    height: 340px;
                    flex-direction: column;
                    &.item {
                        width: 100%;
                        &.border {
                            border-left: unset;
                            border-top: 1px solid #262626;
                        }
                    }
                }
            }
        }

        .apply-for-trial-help {
            position: absolute;
            border-radius: 0 0 @modal-border-radius @modal-border-radius;
            bottom: 0;
            left: 0;
            height: 40px;
            width: 100%;
            background: rgba(255, 255, 255, 0.2);

            display: flex;
            justify-content: center;
            align-items: center;
            user-select: text;
        }
        .loading-mask {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
        }

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            cursor: pointer;
            &:hover {
                background-color: blue;
                border-radius: 20px 0 0 20px;
            }
            &.border {
                border-left: 1px solid #262626;
                &:hover {
                    background-color: blue;
                    border-radius: 0 20px 20px 0;
                }
            }
            @media (max-width: @screen-mobile-width) {
                width: 100%;
                &:hover {
                    background-color: unset;
                    border-radius: 20px 20px 0px 0px;
                }

                &.border {
                    border-left: unset;
                    border-top: 1px solid #262626;
                    &:hover {
                        background-color: unset;
                        border-radius: 0px 0px 20px 20px;
                    }
                }
            }
        }

        .text {
            height: 23px;
            margin-left: 32px;
            font-size: 20px;
            font-weight: 500;
        }
        .item img {
            width: 50px;
            height: 50px;
        }
    }
    :deep(.ant-modal-mask) {
        backdrop-filter: blur(20px);
        background-color: rgba(102, 102, 102, 0.4);
    }
</style>
<style lang="less">
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
