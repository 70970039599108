<template>
    <div>
        <CommonComponentsModalFormCN v-if="isCN" :form-type="formType" />
        <!-- 纷享销客说明文档！！！apps/main/components/Modal/helpFXXK.jpg -->
        <template v-else>
            <CommonComponentsModalFormMobileEN v-if="formType === 'mobile'" :form-type="formType" />
            <CommonComponentsModalFormEN v-else :form-type="formType" />
        </template>
    </div>
</template>

<script lang="ts" setup>
    withDefaults(
        defineProps<{
            formType?: 'dialog' | 'mobile';
        }>(),
        { formType: 'dialog' },
    );

    const isCN = ifCNLocale();
</script>

<style lang="less" scoped>
    @input-border-color: rgba(256, 256, 256, 0.8);
    @placeholder-font-color: rgba(256, 256, 256, 0.3);
    .custom-input {
        background: none;
        border: none;
        border-bottom: 1px solid @input-border-color;
        box-shadow: none;
        border-radius: 0;
    }
    .apply-for-trial-form {
        color: #fff;
        font-family: Montserrat, 'HarmonyOS Sans SC';
        font-size: @font-size-md;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        & {
            width: 100%;
            padding: 0 20px;
            // margin: 0 auto;

            :deep(.ant-form) {
                font-family: 'HarmonyOS Sans SC';
                .scroll-y-container {
                    max-height: 500px;
                    overflow-y: scroll;
                    overscroll-behavior: contain;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    height: 60vh;
                    padding: 2px;

                    // &::-webkit-scrollbar {
                    //     width: 4px;
                    //     // padding-left: 2px;
                    // }
                }
                .ant-form-item {
                    .ant-form-item-label {
                        padding-right: 10px;
                    }

                    &.products_interested_in {
                        margin-bottom: 0;
                    }
                    .ant-select {
                        .ant-select-selector {
                            border: none;
                            border-radius: 0;
                            border-bottom: 1px solid @input-border-color;
                            box-shadow: none !important;
                            background-color: transparent;
                            min-width: 44px;
                            .ant-select-selection-placeholder {
                                color: @placeholder-font-color;
                            }

                            .ant-select-selection-item {
                                // font-size: 12px;
                                color: #fff;
                            }
                        }
                        .ant-select-arrow {
                            & .anticon {
                                & > svg {
                                    color: #fff;
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                        }
                    }
                    .tel-input {
                        border-bottom: 1px solid @input-border-color;
                        .ant-input {
                            border-color: transparent !important;
                        }

                        .ant-select {
                            .ant-select-selector {
                                border: none;
                                background-color: rgba(256, 256, 256, 0.1);
                                height: 24px;
                                width: 77px;
                                min-width: 44px;
                                display: flex;
                                align-items: center;

                                .ant-select-selection-item {
                                    font-size: 12px;
                                    display: inline-block;
                                    width: 100%;
                                    overflow-x: hidden;
                                    padding-right: 8px;
                                    text-align: left;
                                }
                            }
                            .ant-select-arrow {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                & .anticon {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    & > svg {
                                        color: #fff;
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .ant-checkbox-group.custom-checkbox-group {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-row-gap: 1px;
                        .ant-checkbox-wrapper {
                            margin-left: 0;
                            white-space: nowrap;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            .ant-checkbox {
                                transform: translateY(-4px); // 对齐文字
                            }

                            .ant-checkbox-checked {
                                .ant-checkbox-inner {
                                    background-color: #fff;
                                }
                            }
                            .ant-checkbox-inner {
                                // 设置 checkbox 的大小 默认16px
                                width: 12px;
                                height: 12px;

                                // 设置对勾的 大小 和 位置
                                &::after {
                                    top: 4.5px;
                                    left: 2px;
                                    width: 4px;
                                    height: 7px;

                                    border: 1.5px solid #000;

                                    border-top: 0;
                                    border-left: 0;
                                }
                            }
                        }
                    }

                    .ant-input {
                        color: #fff;
                        .custom-input();

                        &:hover,
                        &:focus {
                            .custom-input();
                        }

                        &::placeholder {
                            color: @placeholder-font-color;
                        }
                    }

                    .ant-btn.ant-btn-link {
                        height: 30px;
                        color: #ffffff;
                        font-size: 12px;
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .ant-input-group-addon {
                        height: 31px;
                        background-color: transparent;
                        border-left: 0;
                        border-top: 0;
                        border-right: 0;
                        position: relative;
                        border-color: transparent !important;
                        // &:first-child {
                        //     padding: 8px 8px 8px 0;
                        // }
                        &:not(:last-child)::after {
                            content: '';
                            width: 1px; //边框宽度
                            height: 12px; //边框长度
                            position: absolute;
                            right: 0; //设置为右边框
                            top: 50%; //在距离顶部88px的地方开始绘制边框
                            transform: translate(0, -50%);
                            background-color: @input-border-color; //边框的颜色
                        }
                    }

                    &-label {
                        text-align: left;
                        label {
                            color: #fff;
                            &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
                                &::after {
                                    display: inline-block;
                                    margin-right: 4px;
                                    font-size: 14px;
                                    font-family: SimSun, sans-serif;
                                    line-height: 1;
                                    content: '*';
                                }
                                &::before {
                                    display: none;
                                }
                            }

                            &::after {
                                content: '';
                            }
                        }
                    }

                    .ant-checkbox-wrapper {
                        .policy-confirm-checkbox-text {
                            white-space: nowrap;
                        }
                        .ant-checkbox,
                        .ant-checkbox-checked {
                            &-inner {
                                background-color: #000000;
                                border: 1px solid rgba(256, 256, 256, 0.6);
                                // &:hover {
                                //     border-color: rgba(256, 256, 256, 0.6) !important;
                                // }
                            }
                        }

                        &,
                        a {
                            color: rgba(256, 256, 256, 0.6);
                            transition: color 0.2s;
                        }
                        &.ant-checkbox-wrapper-checked {
                            &,
                            a {
                                color: #fff;
                            }
                        }
                    }
                }
                .ant-form-item-has-error {
                    .ant-input:focus {
                        box-shadow: none;
                    }
                    .ant-input-group-addon {
                        border-color: @input-border-color;
                    }
                }
                .apply-for-trial-form-submit-btn {
                    .ant-form-item-control-input-content {
                        display: flex;
                        justify-content: center;
                    }
                }

                .policy-confirm {
                    .ant-form-item-control-input-content {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        &.en {
            padding: 0 10px 0 0;

            :deep(.ant-form) {
                .scroll-y-container {
                    height: 60vh;
                    margin-bottom: 20px;
                    padding: 2px;
                }
                .ant-form-item-has-error {
                    .ant-input:focus {
                        box-shadow: none;
                    }
                    .ant-input-group-addon {
                        border-color: @input-border-color;
                    }
                    .ant-form-item-explain-error {
                        font-size: 12px;
                    }
                }

                .ant-form-item {
                    &-label {
                        text-align: right;
                    }
                }
            }
        }

        &.mobile {
            padding: 0;
            width: 100%;

            :deep(.ant-form) {
                height: 70vh;

                display: flex;
                flex-direction: column;
                gap: 18px;

                .scroll-y-container {
                    max-height: none;
                    overflow-y: scroll;
                    // overscroll-behavior: contain;
                    display: block;

                    &::-webkit-scrollbar {
                        width: 0;
                        height: 0;
                        // padding-left: 2px;
                    }
                }

                .fixed-bottom-container {
                    width: 100%;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translate(-50%);

                    .ant-form-item {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.en.mobile {
            width: 100%;
            padding: 0;

            :deep(.ant-form) {
                height: 70vh;
                display: flex;
                flex-direction: column;
                gap: 18px;
                .button-container {
                    padding: .convert(12px) [ @vw-mobile];
                }

                .scroll-y-container {
                    height: 70vh;
                    margin-bottom: 20px;
                    padding: 0;
                    max-height: none;
                    overflow-y: scroll;
                    // overscroll-behavior: contain;
                    display: block;
                }
                .scroll-y-container {
                    &::-webkit-scrollbar {
                        width: 0;
                        height: 0;
                        // padding-left: 2px;
                    }
                }
                .ant-form-item-has-error {
                    .ant-input:focus {
                        box-shadow: none;
                    }
                    .ant-input-group-addon {
                        border-color: @input-border-color;
                    }
                    .ant-form-item-explain-error {
                        font-size: 12px;
                    }
                }

                .ant-form-item {
                    &-label {
                        text-align: left;
                    }
                }
                .fixed-bottom-container {
                    width: 100%;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translate(-50%);

                    .ant-form-item {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>
